<template>
  <div oncontextmenu="return false;" onmousedown='return false' onselectstart='return false'>
    <section class="text-gray-700 body-font">
      <div
          class="container flex flex-col items-center px-5 py-16 mx-auto md:flex-row"
      >
        <div
            class="flex flex-col items-center w-full pt-0 mb-16 text-left lg:flex-grow md:w-1/2 xl:mr-20 md:pr-24 md:items-start md:text-left md:mb-0 lg:text-center"
        >
          <h2
              class="mb-1 px-1 text-xs font-medium tracking-widest text-white title-font"
          >
            INFORMATION IN ACCORDANCE WITH SECTION 5 TMG
          </h2>
          <h1 class="mb-6 text-5xl font-bold text-white leading-snug">
            Imprint
          </h1>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >
            Vincent Molnár<br/>Bahnhofstr. 17<br/>09618 Brand-Erbisdorf<br/>Germany<br/>
            <br/>E-Mail: globalchataddon@gmail.com<br/>
            Telephone: +49 3732 2527326<br/>
          </p>
          <h5 class="text-white"><b>Accountability for content</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >
            The contents of our pages have been created with the utmost care.
            However, we cannot guarantee the contents' accuracy, completeness or
            topicality. According to statutory provisions, we are furthermore
            responsible for our own content on these web pages. In this matter,
            please note that we are not obliged to monitor the transmitted or
            saved information of third parties, or investigate circumstances
            pointing to illegal activity. Our obligations to remove or block the
            use of information under generally applicable laws remain unaffected
            by this as per §§ 8 to 10 of the Telemedia Act (TMG).
          </p>
          <h5 class="text-white"><b>Accountability for links</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >
            Responsibility for the content of external links (to web pages of
            third parties) lies solely with the operators of the linked pages.
            No violations were evident to us at the time of linking. Should any
            legal infringement become known to us, we will remove the respective
            link immediately.
          </p>
          <h5 class="text-white"><b>Copyright</b></h5>
          <p
              class="mb-8 text-base leading-relaxed text-center text-white opacity-80 lg:text-left lg:text-1xl"
          >
            Our web pages and their contents are subject to German copyright
            law. Unless expressly permitted by law, every form of utilizing,
            reproducing or processing works subject to copyright protection on
            our web pages requires the prior consent of the respective owner of
            the rights. Individual reproductions of a work are only allowed for
            private use. The materials from these pages are copyrighted and any
            unauthorized use may violate copyright laws.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ImprintComponent",
};
</script>
