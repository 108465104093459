<template>
  <div class="imprint">
    <ImprintComponent/>
  </div>
</template>

<script>
import ImprintComponent from '@/components/ImprintComponent.vue'

export default {
  name: 'Imprint',
  components: {
    ImprintComponent
  }
}
</script>
